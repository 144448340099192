<template>
    <div class="top">
        <div class="logo">
            <router-link to="/"><img alt="KimberlyHornberg.com" src="./assets/logo-kh.png" class="logo" /></router-link>
        </div>
        <div>
            <div class="mainTitle">Kimberly Hornberg</div>
            <NavbarView />
        </div>
    </div>
    <router-view />
    <FooterView />
</template>

<script>
import NavbarView from "./components/NavbarView.vue"
import FooterView from "./components/FooterView.vue"

export default {
    components: { NavbarView, FooterView },
}
</script>


<style>
body {
    background: #efebdf;
}

#app {
    background: #efebdf;
    font-family: "Nunito Sans", sans-serif, Helvetica, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-optical-sizing: auto;
    font-weight: 350;
    font-style: normal;
    font-size: 14px;

    /*text-align: center;*/
    color: #2c3e50;
}

h1 {
    font-family: "Alex Brush", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 550;
    font-style: normal;
    font-size: 48px;
}

h2 {
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}

h3 {
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 150;
    font-style: normal;
}

h3 {
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
}

.top {
    display: inline-flex;
    /*flex;*/
    justify-content: space-around;
    /* space-between;*/
    align-items: center;
}

.mainTitle {
    text-align: center;
}

button {
    margin: 0 10px;
    padding: 10px;
    border: none;
    border-radius: 4px;
}


div .logo {
    text-align: left;
    width: 100px;
    margin-top: -20px;
}

img .logo {
    /* text-alsign: left; */
}
</style>
