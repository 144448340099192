<template>
    <div class="hello">

    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>